import * as XLSX from 'xlsx'

const createSheet = (tableData: any[]) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Tags')

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, 'tags.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item) => {
      return {
        'Nome da Tag': item.nome ?? '',
        'Tipo de Informação': item.tipoDeInformacao ?? '',
        Descrição: item.descricao ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item))
      createSheet(situationItems)
    },
  }
}
