<template>
  <b-card-code no-body>
    <b-card-body>
      <b-row>
        <b-col md="2">
          <b-form-group label="Pesquisar Por" label-for="opcao">
            <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" @change="clearFilters" />
          </b-form-group>
        </b-col>

        <b-col md="4" v-if="pesquisar.tipoOpcoes == enumTipoPesquisar.nomeDaTag">
          <b-form-group label="Nome da Tag" label-for="nomeDaTag">
            <b-form-input
              id="nomeDaTag"
              name="nomeDaTag"
              placeholder="Nome da Tag"
              v-model="pesquisar.nomeDaTag"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>

        <b-col md="3" v-if="pesquisar.tipoOpcoes == enumTipoPesquisar.tipoDeInformacao">
          <b-form-group label="Tipo de Informação" label-for="tipoDeInformacaoSelect">
            <b-form-select
              id="tipoDeInformacaoSelect"
              name="tipoDeInformacaoSelect"
              :options="tipoDeInformacaoOptions"
              v-model="pesquisar.tipoDeInformacao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>

        <b-col md="4" v-if="pesquisar.tipoOpcoes == enumTipoPesquisar.descricao">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input
              id="descricao"
              name="descricao"
              placeholder="Descrição"
              v-model="pesquisar.descricao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>

        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="carregarGrid()"
          >
            Pesquisar
          </b-button>
        </b-col>

        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="exportar()"
          >
            Exportar
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-table
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="editar(row.item.id)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>

          <b-dropdown-item @click="excluir(row.item.id)">
            <feather-icon icon="XIcon" size="16" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <b-modal id="modal-cadastro" ref="modal-cadastro" centered title="Cadastro" no-close-on-backdrop>
      <validation-observer ref="formTag">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Nome da Tag" label-for="nome">
                <validation-provider #default="{ errors }" name="Nome da Tag" rules="required">
                  <b-form-input id="nome" type="text" placeholder="Nome da Tag" v-model="dados.nome" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-textarea id="descricao" size="lg" placeholder="Descrição" v-model="dados.descricao" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer="{}">
        <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro')"> Fechar </b-button>
        <b-button variant="primary" @click="salvar" :disabled="loading">
          {{ !loading ? 'Salvar' : '' }}
          <b-spinner small v-if="loading" />
        </b-button>
      </template>
    </b-modal>
  </b-card-code>
</template>
<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { jwtDecode } from 'jwt-decode'
  import { asDefault } from './exportTableTags'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: true,
        enumTipoPesquisar: { nomeDaTag: 1, tipoDeInformacao: 2, descricao: 3 },
        enumTipoDeInformacao: {
          Gerais: 'Dados Gerais do Tomador',
          Segurado: 'Dados do Segurado',
          'Dados Bancários': 'Dados Bancários do Tomador',
          Instituto: 'Dados do Instituto',
          Contrato: 'Dados do Contrato',
          Outros: 'Outras Informações',
        },
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          tipoDeInformacao: '',
          nomeDaTag: '',
          institutoId: 0,
        },
        required,
        tipoOpcoes: [
          { value: 1, text: 'Nome da Tag' },
          { value: 2, text: 'Tipo de Informação' },
          { value: 3, text: 'Descrição' },
        ],
        tipoDeInformacaoOptions: [
          { value: '', text: 'Selecione' },
          { value: 'Gerais', text: 'Dados Gerais do Tomador' },
          { value: 'Segurado', text: 'Dados do Segurado' },
          { value: 'Dados Bancários', text: 'Dados Bancários do Tomador' },
          { value: 'Instituto', text: 'Dados do Instituto' },
          { value: 'Contrato', text: 'Dados do Contrato' },
          { value: 'Outros', text: 'Outras Informações' },
        ],
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 10,
        currentPage: 1,
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome da Tag', sortable: true },
          {
            key: 'tipoDeInformacao',
            label: 'Tipo de Informação',
            sortable: true,
            formatter: (value) => this.enumTipoDeInformacao[value],
          },
          { key: 'descricao', label: 'Descrição' },
        ],
        items: [],
        dados: {},
        loading: false,
      }
    },
    async mounted() {
      await this.carregarGrid()
    },
    methods: {
      async carregarGrid() {
        this.isBusy = true

        const parametro = {
          ...this.pesquisar,
          institutoId: this.userData.institutoSelecionado,
        }
        await useJwt
          .post('tag/getPesquisar', parametro)
          .then((response) => {
            this.items = response.data
            this.totalRows = this.items.length
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      clearFilters() {
        this.pesquisar.nomeDaTag = ''
        this.pesquisar.tipoDeInformacao = ''
        this.pesquisar.descricao = ''
      },
      editar(item) {
        useJwt
          .edit(`tag`, item)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir a tag selecionada?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`tag`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: error.response.data.error,
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formTag.validate().then((success) => {
          if (success) {
            this.loading = true
            if (!this.dados.id) {
              useJwt
                .post(`tag`, this.dados)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Tag salva com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.$refs['modal-cadastro'].hide()
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = !this.loading
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Atenção!',
                        text: error.response.data.error,
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            } else {
              useJwt
                .put(`tag/${this.dados.id}`, this.dados)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Tag salvo com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.$refs['modal-cadastro'].hide()
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = !this.loading
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Atenção!',
                        text: error.response.data.error,
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            }
          }
        })
      },
      exportar() {
        try {
          this.$message.info('Aguarde...')
          asDefault().export(this.items)
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o excel')
          console.error(error)
        }
      },
    },
  }
</script>

<style scoped>
  .fileLinkAnchor {
    cursor: pointer;
    color: #7367f0;
  }

  .fileLinkAnchor.disabled {
    cursor: wait;
  }
</style>
